import { values } from "../types";
import { ImageComponent } from "./Tags/image";
import { ServicesType } from "../data";
export const ProductComponent = ({
  // _style = "grid lg:grid-cols-4 bg-red-500 place-items-center  items-center justify-center mx-auto md:grid-cols-3 grid-cols-2",
  _style = "md:flex grid grid-cols-2 flex-wrap items-center gap-10 md:justify-center",
  Data = ServicesType,
}: {
  _style?: string;
  Data?: Array<values>;
}): JSX.Element => {
  return (
    <section className={`${_style}  gap-6  `}>
      {Data?.map((item, index) => {
        return (
          <div
            className={`bg-secondary    flex flex-col justify-between   cursor `}
            key={index}
          >
            <div className="p-5">
              <ImageComponent _style="w-44" src={item.img} />
            </div>
            <p className="bg-primarySm capitalize text-center text-light p-3">
              {item.title}
            </p>
          </div>
        );
      })}
    </section>
  );
};
