import React , {useContext} from "react";
import SubLayout from "./common/subLayout";
import { useModal } from "../context/modal";

import { ButtonComponent } from "../components/Tags/button";

import danger from "../assets/danger.png";
import mask from "../assets/mask.png";
import { Context } from "../context/UserDetails";
import { ButtonType } from "../data";
export const CancelComponent: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user, locations,values, setValues, setSelectedItems } = contextValue;

  const CloseActiveModal = () => {
    setModalToggle(initialState);
    setOpen(false);
    setSelectedItems([]);
  };
  return (
    <SubLayout open={open} setOpen={setOpen}>
      <div className="flex items-center flex-col gap-5">
        <img className="w-44" src={danger} alt="danger" />
        <h1 className="text-dark text-center font-semibold text-xl">
          Are you sure you want to cancel this transaction
        </h1>
        <div className="my-4 flex gap-3 items-center justify-between">
          <ButtonComponent
            _style="border-primary w-44 px-1 border-[1px]"
            title="No, Dont't Cancel"
            clickHandler={() => setOpen(false)}
          />
          <ButtonComponent
            _style="border-primary w-44 px-1 border-[1px]"
            type="dark"
            title="Yes, Cancel"
            clickHandler={CloseActiveModal}
          />
        </div>
      </div>
    </SubLayout>
  );
};


export const DeleteComponent: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user, locations,values, setValues, setSelectedItems } = contextValue;

  const CloseActiveModal = () => {
    setModalToggle({...initialState, openDetails : false});
    setOpen(false);
    setSelectedItems([]);
  };

  const reOpen =()=> {
    setModalToggle({...initialState, openDetails : true});
    setOpen(false);
  }
  return (
    <SubLayout open={open} setOpen={setOpen}>
      <div className="flex items-center flex-col gap-5">
        <img className="w-44" src={danger} alt="danger" />
        <h1 className="text-dark text-center font-semibold text-xl">
          Are you sure you want to cancel this booking?
        </h1>
        <div className="my-4 flex gap-3 items-center justify-between">
          <ButtonComponent
            _style="border-primary w-44 px-1 border-[1px]"
            title="No, Dont't cancel"
            clickHandler={reOpen}
          />
          <ButtonComponent
            _style="border-primary w-44 px-1 border-[1px]"
            type="dark"
            title="Yes, cancel"
            clickHandler={CloseActiveModal}
          />
        </div>
      </div>
    </SubLayout>
  );
};

export const DoneComponent: React.FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen }): JSX.Element => {
  const contextValue = useContext(Context);
  const { user, locations,values, setValues, setSelectedItems } = contextValue;

  function convertTo12HourFormat(time24 : string) {
    const [hours24, minutes] = time24.split(":").map(Number);
    let hours12 = hours24 % 12 || 12; // Convert to 12-hour format and handle midnight (00:00)
    const meridiem = hours24 >= 12 ? "PM" : "AM";
  
    return `${hours12?.toString()?.padStart(2, "0")}:${minutes?.toString()?.padStart(2, "0")} ${meridiem}`;
  }
  return (
    <SubLayout type="done" open={open} setOpen={setOpen}>
      <div className="flex flex-col justify-center items-center text-center  gap-5">
        <img className="w-44" src={mask} alt="done " />
        <h1 className="text-xl">
          Hey {values?.firstName}! we have received your booking & your
          <strong> Booking Id: {values?.bookingId}</strong> ,we will be at your place on
          <strong> {values?.pickUpDate}</strong> around <strong>{convertTo12HourFormat(values?.pickUpTimeOne)} - {convertTo12HourFormat(values?.pickUpTimeTwo)}</strong>
        </h1>
        <button
              className={`${ButtonType['dark']}} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md w-40`}
          onClick={()=>{
            values.firstName = user?.fullName as string;
            values.email =  user?.email as string;
            values.phone = '';
            values.address = "";
            values.pickUpDate = "";
            values.pickUpTimeOne =  '';
            values.pickUpTimeTwo =  '';
            setOpen(false)
          }}
        >
          Done
        </button>
      </div>
    </SubLayout>
  );
};
