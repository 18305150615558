import { PropsWithChildren, useState } from "react";
import Layout from "./layout";
import { ButtonComponent } from "../components/Tags/button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import ResetPassword from "./resetPassword";
import RequestTokenComponent from "./requestToken";
import { InputComponent } from "../components/Tags/input";
import { NavLink, useNavigate } from "react-router-dom";
import {useEffect} from 'react'
import { getUser } from "../context/UserDetails";
import { ButtonType } from "../data";
import { requestResetToken } from "../api/handlers";
const PasswordReset = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    if (loggedIn) {
      navigate('/dashboard');
    }
  },[])
  const [heading, setHeading] = useState({
    title: "Forgot Password",
    text: "Enter the email you used to register on the system",
    subTitile: "",
  });

  const swiperProps = {
    autoHeight: false,
    allowTouchMove: false,
    slidesPerView: 1,
    spaceBetween: 20,
  };

  const handleSubmit = async () =>{
    try {
      setLoading(true);
      const response = await requestResetToken({email}); 
      setLoading(false);
      if(response.status == 200) {
        toast.success(response.message);
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      {!loggedIn && <Layout
        subTitle={heading.subTitile}
        title={heading.title}
        text={heading.text}
      >
        <Swiper className="max-w-[95vw] md:max-w-[35vw]" {...swiperProps}>
          <SwiperSlide>
            <div className="">
                <div className="flex my-3 flex-col">
                    <label className="pb-3 text-sm" htmlFor="password">
                        Email
                    </label>
                    <input
                        className={`  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-2 shadow-md`}
                        type='text'
                        value={email}
                        onChange={(e)=>setEmail(e.currentTarget.value)}
                    />
                </div>
                <div className="my-5 gap-5 flex flex-col">
                    <button
                    className={`${loading && 'not-allowed'} ${ButtonType[loading ? 'light' : 'dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md py-4`}
                    onClick={(e)=>{
                      e.preventDefault();
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if(emailRegex.test(email)){
                        !loading && handleSubmit();
                      }else{
                        toast.error("Enter a valid email");
                        setEmail('');
                      }
                    }}    
                    >
                    {loading ? "Loading..." : "Reset Password"}
                    </button>
                    <button
                    className={`${ButtonType['light']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md py-4`}
                    onClick={()=>navigate('/login')}
                    >
                    Back to Login
                    </button>
                </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </Layout>}
      <ToastContainer />
    </>
  );
};

export default PasswordReset;
