import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useState, useEffect } from "react";
import { ResponseBody, getCurrentMonthBookingStats, getUserMonthlyBookingOverview } from "../api/handlers";
import { UserInfo } from "../context/UserDetails";

const Charts : React.FC<{user : UserInfo | null }> = ({user}): JSX.Element => {
  const [barData,setBarData] = useState<Array<number>>([0,0,0,0]);
  const [barCategories, setBarCategories] = useState<Array<string>>([]);
  const [pieData,setPieData] = useState<Array<number>>([0,0,0,0]);
  const [pieCategories, setPieCategories] = useState<Array<string>>(['', '', '']);

  useEffect(()=>{
    async function getBarData(){
      try {
        const response : ResponseBody = await getCurrentMonthBookingStats(user?.accessToken as string);
        if(response.status == 201){
          const values : Array<number> | any = Object.values(response?.data).slice(1) || [];
          const keys : Array<string> | any = Object.keys(response?.data).slice(1) || [];
          setBarData(values);
          setBarCategories(keys);
        }
      } catch (error) {
  
      }
  
    } 
    getBarData();
  },[])
  useEffect(()=>{
    async function getPieData(){
      try {
        const response : ResponseBody = await getUserMonthlyBookingOverview(user?.accessToken as string);
        if(response.status == 201){
          const values : Array<number> | any = Object.values(response?.data) ?? [0, 0, 0];
          const keys : Array<string> | any = Object.keys(response?.data) ?? ["", "", ""];
          
          setPieData(values)
          setPieCategories(keys)
        }
      } catch (error) {
  
      }
  
    } 
    getPieData();
  },[])
 

  const barChartOptions: ApexOptions = {
    xaxis: {
      categories: barCategories,
    },
    // colors: ["#BAEDBD", "#C6C7F8", "#000000", "#B1E3FF"],
    colors: ["#C6C7F8", "#000000", "#B1E3FF"],

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },

    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        borderRadiusApplication: "end",
        borderRadius: 8,

        horizontal: false,
      },
    },

    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  const barStyle = {
    borderRadius: "4px",
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  };
  const series1 = [
    {
      name: "data",
      data: barData,
    },
  ];
  const series2 = pieData;

  const donutOptions = {
    chart: {
      width: 100,
    },
    // colors: ["#BAEDBD", "#C6C7F8", "#000000", "#FCB495"],
    colors: [ "#C6C7F8", "#000000", "#FCB495"],
    plotOptions: {
      formatter: function (val: number) {
        return `  `;
      },
      pie: {
        startAngle: -90,
        endAngle: 270,

        donut: {
          size: "68%",
        },
      },
    },
    labels: pieCategories,
    fill: {
      type: "gradient",
    },
    dataLabels: {
      enabled: true,
    },
  };

  return (
    <main className="my-5">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-5    ">
        <div className="-z-10 bg-[#F7F9FB] p-3 rounded-2xl">
          <h1 className="text-base font-medium font-gsans-medium">
            Recently monthly bookings
          </h1>
          <Chart
            type="bar"
            style={barStyle}
            options={barChartOptions}
            series={series1}
          />
        </div>
        <div className=" -z-10 bg-[#F7F9FB] p-3 rounded-2xl">
          <h1 className="text-base font-medium font-gsans-medium">
            Total bookings
          </h1>
          <Chart type="donut" options={donutOptions} series={series2} />
        </div>
      </section>
    </main>
  );
};

export default Charts;
