import Layout from "./layout";
import { InputComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";

import { PropsWithChildren, useState, useContext } from "react";

import { values } from "../types";

import { RegisterValues } from "../data";
import { useNavigate } from "react-router";

import { validateComponent } from "../utils/validate";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import { useEffect } from "react";

import PasswordComponent from "./password";
import VerifyEmail from "./verifyEmail";
import { ResponseBody, loginHandler, registerHandler } from "../api/handlers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context, getUser } from "../context/UserDetails";

const Register = (): JSX.Element => {
  const [values, setValues] = useState<values>(RegisterValues);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const contextValue = useContext(Context);
  const { user, setUser } = contextValue;
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  const navigate = useNavigate();

  const [heading, setHeading] = useState({
    title: "Welcome!",
    text: "Unlock Your World: Create your account.",
    subTitile: "Profile Details",
  });

  const validateInputs = async () => {
    const validate = validateComponent(values);
    if (Object.keys(validate).length === 0) {
      submitRegister();
    } else {
      const keys = Object.keys(validate);
      const firstKey = keys[0];
      const firstValue = validate[firstKey];
      toast.error(firstValue);
    }
  };

  const submitRegister = async () => {
    try {
      setLoading(true);
      const response: ResponseBody = await registerHandler(
        values.email,
        values.password,
        values.phone,
        values.fullName
      );
      setLoading(false);

      if (response.status == 201) {
        localStorage.setItem("user", JSON.stringify(response?.data));
        setUser(response?.data);
        setOpen(true);
      } else {
        toast.error(response.message);
        console.log(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const SwiperNextButton = ({ children }: PropsWithChildren) => {
    const swiper = useSwiper();

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      // const validate = validateComponent(values);

      setHeading({
        ...heading,
        title: "Stage 2",
        text: "Unlocking Nigeria's Renewable Energy Potential Through Effective Waste Management.",
        subTitile: "Create Password",
      });
      swiper.slideNext();
    };

    return (
      <ButtonComponent
        clickHandler={(e) => {
          handleSubmit(e);
        }}
        _style="py-4"
        type="dark"
        title="Create Account"
      />
    );
  };

  const SwiperPrevButton = ({ children }: PropsWithChildren) => {
    const swiper = useSwiper();

    const BackToRegister = () => {
      setHeading({ ...heading, subTitile: "Create Account" });
      swiper.slidePrev();
    };

    return (
      <ButtonComponent
        clickHandler={BackToRegister}
        _style="py-4 w-full border-[0.4px] border-primary"
        title="Back"
      />
    );
  };

  const swiperProps = {
    autoHeight: false,
    allowTouchMove: false,
    slidesPerView: 1,
    spaceBetween: 20,
  };

  return (
    <>
      {!loggedIn && (
        <>
          <div className="z-50">
            {" "}
            <VerifyEmail open={open} setOpen={setOpen} />
          </div>
          <div className={`${open && "hidden"}`}>
            <Layout
              title={heading.title}
              text={heading.text}
              subTitle={heading.subTitile}
            >
              <Swiper className="max-w-[95vw] md:max-w-[40vw]" {...swiperProps}>
                <SwiperSlide>
                  <form className="">
                    <InputComponent
                      _style="  border-[1px] rounded-md shadow-none p-3 border-[#C2C2C2]"
                      values={values}
                      setValues={setValues}
                      name="fullName"
                      label="Full Name (company/individual)"
                      placeholder="full name"
                    />
                    <InputComponent
                      _style="  border-[1px] rounded-md shadow-none p-3 border-[#C2C2C2]"
                      values={values}
                      setValues={setValues}
                      name="phone"
                      label="Phone Number"
                      placeholder="0800000001"
                    />

                    <InputComponent
                      _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                      values={values}
                      setValues={setValues}
                      name="email"
                      label="Email"
                      placeholder="gjc@gmail.com"
                    />
                    <div className="my-5 gap-5 flex flex-col">
                      <SwiperNextButton />
                      <ButtonComponent
                        clickHandler={() => navigate("/login")}
                        _style="py-3 font-black border-2 mb-2"
                        title="Sign in"
                      />
                    </div>
                  </form>
                </SwiperSlide>
                <SwiperSlide>
                  <PasswordComponent
                    setValues={setValues}
                    values={values}
                    setOpen={setOpen}
                    validateInputs={validateInputs}
                    loading={loading}
                  />
                  <SwiperPrevButton />
                </SwiperSlide>
              </Swiper>
            </Layout>
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );
};

export default Register;
