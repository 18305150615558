import React, { createContext, useState, PropsWithChildren, useEffect } from 'react';
import { getAllItems, getAllLocations } from '../api/handlers';
import { valueProps } from '../booking/booking';

export type UserInfo = {
  id?: string;
  fullName?: string;
  email?: string;
  phone?: string;
  accessToken?: string;
  userId?: number;
  roles?: string;
  photo?: string;
  status?: boolean;
  loggedIn?: boolean;
};

export type Items = {
  id?: string;
  name?: string;
  photo?: string;
};

export type Booking = {
  id: string;
  user: string;
  items: Array<string>;
  additionalPhone: string;
  pickUpAddress: string;
  pickUpLocation: string;
  pickUpDate: string;
  pickUpTime: string;
  bookingDate: string;
  status: string;
  bookingId: number;
};

export type Location = {
  id: string;
  name: string;
};

interface ContextValue {
  user: UserInfo | null;
  setUser: (user: UserInfo | null) => void;
  items: Array<Items>;
  locations:Array<Location>;
  selectedItems: Array<Items>;
  setSelectedItems: (selectedItems: Array<Items>) => void;
  selectedBooking: Booking | null;
  setSelectedBooking: (selectedBooking: Booking) => void;
  values: valueProps;
  setValues:  React.Dispatch<React.SetStateAction<valueProps>>;

}

export function getUser(): UserInfo{
  const user : UserInfo = JSON.parse(localStorage?.getItem('user') || "{}") as UserInfo ;
  if( !user?.id || !user?.accessToken || !user?.fullName || !user?.photo || !user?.roles){
    localStorage.removeItem('user');
    return {
      loggedIn : false
    }
  }else if(user?.roles?.toLowerCase() != 'user'){
    localStorage.removeItem('user');
    return {
          loggedIn : false
        }
  }else{
    return {...user,loggedIn: true}
  }
}
export const Context = createContext<ContextValue>({
  user: null,
  setUser: () => {} ,
  items: [],
  locations:[],
  selectedItems : [],
  setSelectedItems: () => {},
  selectedBooking : null,
  setSelectedBooking: () => {},
  values : {},
  setValues : () =>{}
});

const AppContext = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<UserInfo | null>(getUser());
  const [items, setItems] = useState<Array<Items>>([]);
  const [locations, setAllLocations] = useState<Array<Location>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<Items>>([]);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const bookingValues = {
    firstName: user?.fullName as string,
    email: user?.email as string,
    phone: '',
    phone2: "",
    address: "",
    pickUpDate: "",
    pickUpTimeOne: '',
    pickUpTimeTwo: '',
    location : '',
    bookingId : '',
  };
  const [values, setValues] = useState<valueProps>(bookingValues);


  useEffect(()=>{
    async function getAcceptableItems (){
      try {
        const allItems = await getAllItems();      
        setItems(allItems.data);
      } catch (error) {
        
      }
      
    }
    getAcceptableItems();
  },[])

  useEffect(()=>{
    async function getLocations (){
      if(!user?.accessToken) return false
      try {
        const allItems = await getAllLocations(user?.accessToken as string);      
        setAllLocations(allItems.data);
      } catch (error) {
        
      }
    }
    getLocations();
  },[user])

  const value: ContextValue = {
    user,
    setUser,
    items,
    locations,
    selectedItems,
    setSelectedItems,
    values,
    setValues,
    selectedBooking,
    setSelectedBooking
  };

  

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  );
};

export default AppContext;
