import Layout from "./common/layout";
import { OverviewComponent } from "./common/overview";
import Charts from "./charts";
import { useEffect, useState, useContext } from "react";
import { Context, UserInfo, getUser } from "../context/UserDetails";
import { useNavigate } from "react-router";
const Index = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const navigate = useNavigate();
  const contextValue = useContext(Context);
  const { user, setUser, items } = contextValue;
  useEffect(()=>{
    if (!loggedIn) {
      navigate('/');
    }
  })
  return (
    loggedIn && <Layout text="Dashboard" user={user as UserInfo}>
      <main className="">
        <h1 className="text-primary font-semibold text-2xl">Hey! {user?.fullName}</h1>
        <p className="font-light font-gsans-light md:text-xl text-base my-4">
          Convert your waste to money & track your waste record
        </p>
        <h1 className="text-dark text-xl my-3 font-bold">Total Overview</h1>
        <OverviewComponent user={user} items={items}/>
        <Charts user={user}/>
      </main>
    </Layout>
  );
};

export default Index;
