import Layout from "./common/layout";
import { InputComponent, SelectComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import React, { useState, useEffect, useContext } from "react";
import { values } from "../types";
import uploadBtn from "../assets/uploadbtn.png";
import profileImg from "../assets/profileImg.png";
import { useNavigate } from "react-router-dom";
import { LanguageData } from "../data";
import { Context, UserInfo, getUser } from "../context/UserDetails";
import { ResponseBody, modifyInfo, modifyUserPassword } from "../api/handlers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BiHide, BiShow } from "react-icons/bi";

type UpdateProps = {
  title: string;
  text: string;
  name: string;
  values: values;
  setValues: React.Dispatch<React.SetStateAction<values>>;
};

const Profile = (): JSX.Element => {
  const [open, setOpen] = useState({
    show1: false,
    show2: false,
    type1: "password",
    type2: "password",
  });

  const handleToggle1 = () => {
    open.show1
      ? setOpen({ ...open, show1: false, type1: "password" })
      : setOpen({ ...open, show1: true, type1: "text" });
  };
  const handleToggle2 = () => {
    open.show2
      ? setOpen({ ...open, show2: false, type2: "password" })
      : setOpen({ ...open, show2: true, type2: "text" });
  };

  const [values, setValues] = useState<values>({});
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const [oldPassword, setOldPassword] = useState<string | undefined>("");
  const [newPassword, setNewPassword] = useState<string | undefined>("");
  const [language, setLanguage] = useState<string | undefined>("english");
  const [imageFile, setImageFile] = useState<string | File>("");
  const contextValue = useContext(Context);
  const { user, setUser } = contextValue;
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedIn) {
      navigate("/");
    }
  }, []);
  const UpdateComponent: React.FC<UpdateProps> = ({
    title,
    setValues,
    values,
    name,
    text,
  }) => {
    return (
      <form className="flex md:flex-row flex-col md:items-center gap-1 md:gap-5 justify-between">
        <div className="flex-1">
          <h1 className="md:text-2xl text-xl font-gsans-medium font-semibold capitalize">
            {title}
          </h1>
          <p className="my-3">{text}</p>
        </div>
        <div className="flex-col flex-1 w-full  flex ">
          <InputComponent
            _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
            values={values}
            setValues={setValues}
            name={name}
            label=""
          />
          <ButtonComponent
            _style="px-3 self-start"
            type="dark"
            title="Save Change"
          />
        </div>
      </form>
    );
  };

  async function modify(
    key: string,
    value: string | File,
    formData: FormData | undefined = undefined
  ) {
    if (!formData && !value) {
      toast.error(`${key} cannot be empty!`);
      return false;
    }
    try {
      const response: ResponseBody = await modifyInfo(
        formData,
        user?.accessToken as string,
        { [key]: value }
      );

      if (response.status == 201) {
        let newUserInfo: UserInfo = response.data;
        newUserInfo.accessToken = user?.accessToken;
        newUserInfo.status = user?.status;
        newUserInfo.loggedIn = user?.loggedIn;
        localStorage.setItem("user", JSON.stringify(newUserInfo));
        setUser(newUserInfo);
        toast.success(`Success!`);
        return true;
      } else {
        toast.error(response.message);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function modifyPassword(data: any = {}) {
    try {
      const response: ResponseBody = await modifyUserPassword(
        user?.accessToken as string,
        data
      );
      console.log(response);

      if (response.status == 200) {
        toast.success(`Success!`);
        return true;
      } else {
        toast.error(response.message);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  useEffect(()=>{
    let user = getUser();
    setValues({
      fullName: user?.fullName as string,
      email: user?.email as string,
      phone: user?.phone as string,
    });
  },[]);

  return (
    <>
      {loggedIn && (
        <Layout showBooking={false} text="Profile" user={user as UserInfo}>
          <section>
            <div className="flex   my-3 justify-between">
              <div className="relative">
                <div className=" h-44 w-44 rounded-full overflow-hidden">
                  <img
                    src={user?.photo}
                    alt="profile image"
                    className="w-full object-cover"
                    // className="user-photo-profile"
                  />
                  <label
                    className="absolute right-3 bottom-0"
                    htmlFor="profile_image"
                  >
                    <img src={uploadBtn} alt="uploadBtn" />
                  </label>
                  <input
                    className="hidden"
                    type="file"
                    id="profile_image"
                    name="profile_image"
                    accept="image/*"
                    onChange={(e) => {
                      if (
                        e.currentTarget.files &&
                        e.currentTarget.files?.length > 0
                      ) {
                        const formData = new FormData();
                        formData.append("photo", e.currentTarget.files[0]);
                        modify("", "", formData);
                      }
                    }}
                  />
                </div>
              </div>
              <h1 className="md:text-2xl text-xl font-light-font-gsans-light">
                User ID : {user?.userId}
              </h1>
            </div>
            <div className="my-5 flex flex-col gap-5">
              <form className="flex md:flex-row flex-col md:items-center gap-1 md:gap-5 justify-between">
                <div className="flex-1">
                  <h1 className="text-2xl font-gsans-medium font-seimibold capitalize">
                    Full Name
                  </h1>
                  <p className="my-3">your full name</p>
                </div>
                <div className="flex-col flex-1 w-full  flex ">
                  <InputComponent
                    _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                    values={values}
                    setValues={setValues}
                    name="fullName"
                    type="text"
                    label=""
                    placeholder="Full Name"
                  />
                  {/* <button
                    className={`bg-primary text-white font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-3 self-start`}
                    onClick={async (e) => {
                      e.preventDefault();
                      modify("fullName", values.fullName);
                      values.fullName = "";
                    }}
                  >
                    Save Changes
                  </button> */}
                </div>
              </form>
              <form className="flex md:flex-row flex-col md:items-center gap-1 md:gap-5 justify-between">
                <div className="flex-1">
                  <h1 className="text-2xl font-gsans-medium font-seimibold capitalize">
                    Email
                  </h1>
                  <p className="my-3">
                    Used to sign into and recieve notifications for this account
                  </p>
                </div>
                <div className="flex-col flex-1 w-full  flex ">
                  <InputComponent
                    _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                    values={values}
                    setValues={setValues}
                    name="email"
                    type="text"
                    label=""
                    placeholder="Email"
                  />
                  {/* <button
                    className={`bg-primary text-white font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-3 self-start`}
                    onClick={(e) => {
                      e.preventDefault();
                      modify("email", values.email);
                      values.email = "";
                    }}
                  >
                    Save Changes
                  </button> */}
                </div>
              </form>
              <form className="flex md:flex-row flex-col md:items-center gap-1 md:gap-5 justify-between">
                <div className="flex-1">
                  <h1 className="text-2xl font-gsans-medium font-seimibold capitalize">
                    Phone Number
                  </h1>
                  <p className="my-3">your phone number</p>
                </div>
                <div className="flex-col flex-1 w-full  flex ">
                  <InputComponent
                    _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
                    values={values}
                    setValues={setValues}
                    name="phone"
                    type="text"
                    label=""
                    placeholder="your phone number"
                  />
                  {/* <button
                    className={`bg-primary text-white font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-3 self-start`}
                    onClick={(e) => {
                      e.preventDefault();
                      modify("phone", values.phone);
                      values.phone = "";
                    }}
                  >
                    Save Changes
                  </button> */}
                </div>
              </form>
              <form className="flex md:flex-row flex-col md:items-center gap-1 md:gap-5 justify-between">
                <div className="flex-1">
                  <h1 className="text-2xl font-gsans-medium font-seimibold capitalize">
                    Password
                  </h1>
                  <p className="my-3">change Password</p>
                </div>
                <div className="flex-col flex-1 w-full  flex ">
                  <div className="flex relative my-3 flex-col">
                    <input
                      type={open.type1}
                      className="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-2 shadow-md"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.currentTarget.value)}
                      placeholder="old password"
                    />
                    <span
                      onClick={handleToggle1}
                      className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
                    >
                      {open.show1 ? <BiShow /> : <BiHide />}
                    </span>
                  </div>
                  <div className="flex relative my-3 flex-col">
                    <input
                      type={open.type2}
                      className="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-2 shadow-md"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.currentTarget.value)}
                      placeholder="new password"
                    />
                    <span
                      onClick={handleToggle2}
                      className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
                    >
                      {open.show2 ? <BiShow /> : <BiHide />}
                    </span>
                  </div>
                  <button
                    className={`bg-primary text-white font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-3 self-start`}
                    onClick={async(e) => {
                      e.preventDefault();
                      await modify("fullName", values.fullName);
                      await modify("email", values.email);
                      await modify("phone", values.phone);
                      values.phone = "";
                      values.email = "";
                      values.fullName = "";
                      if (!oldPassword)
                        return toast.error("old password can not be empty");
                      if (!newPassword)
                        return toast.error("new password can not be empty");
                      let data = {
                        oldPassword,
                        newPassword,
                      };
                      modifyPassword(data);
                      setOldPassword("");
                      setNewPassword("");
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
              <form className="flex md:flex-row flex-col md:items-center gap-1  md:gap-5 justify-between">
                <div className="flex-1">
                  <h1 className="text-2xl font-gsans-medium font-seimibold capitalize">
                    Language
                  </h1>
                  <p className="my-3">
                    Lorem ipsum dolor sit amet consectetur. Quam pellentesque
                    viverra a quis pellentesque nunc porttitor.
                  </p>
                </div>
                <div className="flex-col flex-1 w-full  flex ">
                  <div className="flex my-3 flex-col">
                    <label
                      className="pb-3 text-sm"
                      htmlFor={"language"}
                    ></label>
                    <select
                      className={`  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2] text-sm outline-none p-3 shadow-md`}
                      name={"language"}
                      onChange={(e) => setLanguage(e.currentTarget.value)}
                    >
                      <option value="">Select a language</option>
                      <option value="english">English</option>
                      <option value="french">French</option>
                    </select>
                  </div>
                  <button
                    className={`bg-primary text-white font-light text-base text-light font-gsans-light px-5 py-2 rounded-md px-3 self-start`}
                    onClick={(e) => {
                      e.preventDefault();
                      modify("language", language as string);
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </section>
        </Layout>
      )}
      <ToastContainer />
    </>
  );
};

export default Profile;
