import { modalProps } from "../types";
import Layout from "./common/layout";

import { InputComponent, SelectComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import { useState, useEffect, useContext } from "react";
import { useModal } from "../context/modal";
import { Context } from "../context/UserDetails";
import { ButtonType } from "../data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export type valueProps = {
  [key: string]: string;
};

const Booking: React.FC<modalProps> = ({ open, name, text }): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user, locations,values, setValues } = contextValue;

  // const bookingValues = {
  //   firstName: user?.fullName as string,
  //   email: user?.email as string,
  //   phone: '',
  //   phone2: "",
  //   address: "",
  //   pickUpDate: "",
  //   pickUpTimeOne: '',
  //   pickUpTimeTwo: ''
  // };

  const verifyValues = () =>{
    if(!values.firstName){
      toast.error("Invalid Full name")
      return false
    }
    if(!values.email){
      toast.error("Invalid Email")
      return false
    }
    if(!values.phone){
      toast.error("Invalid Phone number")
      return false
    }
    if(!values.address){
      toast.error("Invalid Address")
      return false
    }
    if(!values.pickUpTimeOne){
      toast.error("Invalid Pickup time one")
      return false
    }
    if(!values.pickUpTimeTwo){
      toast.error("Invalid Pickup time two")
      return false
    }
    if(!values.pickUpDate){
      toast.error("Invalid Pickup date")
      return false
    }
    if(!selectedLocation){
      toast.error("Invalid Location")
      return false
    }
  }

  const VerifyBooking = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setModalToggle({ ...initialState, reviewOpen: true });
  };
  function compareTimes(time1 :string , time2 : string) {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);
  
    // Create Date objects with arbitrary dates but the specified time
    const date1 = new Date(2000, 0, 1, hours1, minutes1);
    const date2 = new Date(2000, 0, 1, hours2, minutes2);
  
    // Compare the two Date objects
    if (date1 < date2) {
      return -1; // time1 is earlier than time2
    } else if (date1 > date2) {
      toast.error("Invalid pick up time")
      setPickUpTimeOne("");
      setPickUpTimeTwo("");
      return 1; // time1 is later than time2
    } else {
      toast.error("Invalid pick up time")
      setPickUpTimeOne("");
      setPickUpTimeTwo("");
      return 0; // time1 is equal to time2
    }
  }
  

  // const [values, setValues] = useState<valueProps>(bookingValues);
  const [selectedLocation, setSelectedLocation] = useState<string>('');
  const [pickUpTimeOne, setPickUpTimeOne] = useState<string>('');
  const [pickUpTimeTwo, setPickUpTimeTwo] = useState<string>('');

  useEffect(()=>{
    setSelectedLocation(locations[0]?.id || '');
    values.location = locations[0]?.id;
  }, [locations])
  return (
    <Layout width="min-w-[60vw]" name={name} open={open} text={text}>
      <form>
        <section>
          <h2 className="text-2xl capitalize text-primary font-gsans-light">
            Basic Information
          </h2>
          <div className="grid md:grid-cols-2 md:gap-10 gap-1">
            <InputComponent
              name="firstName"
              values={values}
              setValues={setValues}
              label="Full Name"
            />
            <InputComponent
              name="email"
              type="email"
              values={values}
              setValues={setValues}
              label="Email Adress"
            />
          </div>
          <div className="grid md:grid-cols-2 md:gap-10 gap-1">
            <div className="flex my-3 flex-col">
              <label className="pb-3 text-sm" htmlFor='phone'>
                Phone Number
              </label>
              <input
                className='text-sm outline-none p-2 shadow-md'
                type='text'
                value={user?.phone}
                disabled
              />
            </div>
            <InputComponent
              name="phone"
              type="text"
              values={values}
              setValues={setValues}
              label="Additional Phone Number"
            />
          </div>
          <h3 className="text-primary my-3 font-semibold text-sm">
            This number will be used for verification
          </h3>
        </section>
        <section>
          <h2 className="text-2xl capitalize text-primary font-gsans-light">
            Pick Up details
          </h2>
          <div className="grid md:grid-cols-2 md:gap-10 gap-1">
            <InputComponent
              name="address"
              values={values}
              setValues={setValues}
              label="Pick Up Address"
            />
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor={'language'}>
                Select pick up location
                </label>
                <select
                  className='undefined text-sm outline-none p-2 shadow-md'
                  name='location'
                  onChange={(e)=>{
                    setSelectedLocation(e.currentTarget.value);
                    values.location = e.currentTarget.value;
                  }}
                >
                  {locations?.map((item,index) => (
                    <option value={item?.id} key={index}>{item?.name}</option>
                  ))}
                </select>
              </div>
          </div>
          <h3 className="text-primary my-3 font-semibold text-sm">
            Pickup time range is between pickup time one and pickup time two
          </h3>
          <div className="grid md:grid-cols-2 md:gap-10 gap-1">
            <InputComponent
              name="pickUpTimeOne"
              values={values}
              setValues={setValues}
              label="PickUp time one"
              type="time"
            />
            <InputComponent
              name="pickUpTimeTwo"
              values={values}
              setValues={setValues}
              label="PickUp time two"
              type="time"
            />
          </div>
          <div className="grid md:grid-cols-2 md:gap-10 gap-1">
            <InputComponent
              name="pickUpDate"
              values={values}
              type="date"
              setValues={setValues}
              label="Pick Up Date"
            />
          </div>
        </section>
        <div className="flex my-3 items-center justify-center">
        <button
          className={`${ButtonType['dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md w-48 font-gsans-light`}
          onClick={(e) => {
            e.preventDefault();
            if(verifyValues() || compareTimes(values.pickUpTimeOne, values.pickUpTimeTwo) == -1){
              setModalToggle({ ...initialState, reviewOpen: true });
            }
          }}
        >
          Verify Booking
        </button>
        </div>
      </form>
      <ToastContainer />
    </Layout>
  );
};

export default Booking;
