import Layout from "./common/layout";
import { OverviewComponent } from "./common/overview";
import { ButtonComponent } from "../components/Tags/button";
import { AllPickups, headerData, buttonStates } from "../data";
import { useEffect, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { values } from "../types";
import { Booking, Context, UserInfo, getUser } from "../context/UserDetails";
import { getAllBookings, getAllCancelledBookings, getAllNewBookings, getAllPendingBookings, getSuccessfulBookings } from "../api/handlers";
import { useModal } from "../context/modal";

const Bookings = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const contextValue = useContext(Context);
  const { user, setUser, items,locations, setSelectedBooking, selectedBooking } = contextValue;
  const [allBookings, setAllBookings] = useState<Array<Booking>>([]);
  const [allPendingBookings, setAllPendingBookings] = useState<Array<Booking>>([]);
  const [allCancelledBookings, setAllCancelledBookings] = useState<Array<Booking>>([]);
  const [allSuccessfulBookings, setAllSuccessfulBookings] = useState<Array<Booking>>([]);
  const [allNewBookings, setAllNewBookings] = useState<Array<Booking>>([]);
  const navigate = useNavigate();
  useEffect(()=>{
    if (!loggedIn) {
      navigate('/');
    }
  },[])
  const [status, setStatus] = useState("all");
  const [contents, setContents] = useState<Array<Booking>>([]);
  const statusColor: {
    [key: string]: string;
  } = {
    pending: "text-blue-600",
    successful: "text-primary",
    cancelled: "text-red-600",
  };

  const {setModalToggle, initialState, modalToggle} = useModal()

  useEffect(()=>{
    setContents(status == 'all' ? allBookings : status == 'pending' ? allPendingBookings : status == 'successful' ? allSuccessfulBookings : status == 'cancelled' ? allCancelledBookings : []); 
  }, [allBookings,allCancelledBookings,allNewBookings, allPendingBookings, allSuccessfulBookings, status,modalToggle])

  async function getAllPickUps() {
    if(!user?.accessToken) return false
    try {
      const response = await getAllBookings(user?.accessToken as string);
      
      if(response.status == 201){
        setAllBookings(response.data?.reverse());
      }
    } catch (error) {
      console.log(error);
      
    }
  }
  useEffect(()=>{
    getAllPickUps();
  },[selectedBooking,modalToggle])
  useEffect(()=>{
    async function getAllPendingPickUps() {
      if(!user?.accessToken) return false
      try {
        const response = await getAllPendingBookings(user?.accessToken as string);
        
        if(response.status == 201){
          setAllPendingBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
        
      }
    }
    getAllPendingPickUps();
  },[selectedBooking,modalToggle])
  useEffect(()=>{
    async function getAllCancelledPickUps() {
      if(!user?.accessToken) return false
      try {
        const response = await getAllCancelledBookings(user?.accessToken as string);
        
        if(response.status == 201){
          setAllCancelledBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
        
      }
    }
    getAllCancelledPickUps();
  },[selectedBooking,modalToggle])
  useEffect(()=>{
    async function getAllSuccessfulPickUps() {
      if(!user?.accessToken) return false
      try {
        const response = await getSuccessfulBookings(user?.accessToken as string);
        
        if(response.status == 201){
          setAllSuccessfulBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
        
      }
    }
    getAllSuccessfulPickUps();
  },[selectedBooking,modalToggle])
  useEffect(()=>{
    async function getAllNewPickUps() {
      if(!user?.accessToken) return false
      try {
        const response = await getAllNewBookings(user?.accessToken as string);
        
        if(response.status == 201){
          setAllNewBookings(response.data?.reverse());
        }
      } catch (error) {
        console.log(error);
        
      }
    }
    getAllNewPickUps();
  },[selectedBooking,modalToggle])

  const renderData = contents.map((data, index) => {
    return (
      <tr onClick={() => {
        setSelectedBooking(data);
        setModalToggle({...initialState, openDetails : true})
        }} 
        className="text-center cursor-pointer border-b p-2 " key={index}>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {data.bookingId}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {data.bookingDate.substring(0,10)}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {locations?.filter(i =>i?.id == data?.pickUpLocation)[0]?.name || ""}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {data.additionalPhone}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className="p-2 md:p-3 font-gsans-light font-medium"
        >
          {data.pickUpDate.substring(0,10)}
        </td>
        <td
          style={{ whiteSpace: "nowrap" }}
          className={`p-2 md:p-3 ${
            statusColor[data.status.trim()]
          } font-gsans-light font-medium`}
        >
          {data.status}
        </td>
      </tr>
    );
  });
  return (
    <>
      {loggedIn && 
      <Layout text="Bookings" user={user as UserInfo} getPickUps={getAllPickUps}>
        <section>
          <OverviewComponent user={user} items={items}/>
          <nav className="grid md:grid-cols-5 grid-cols-2 md:gap-5 gap-3 my-10">
            {buttonStates.map((data, index) => {
              return (
                <ButtonComponent
                  key={index}
                  type={data.status.trim() == status ? "dark" : "light"}
                  clickHandler={() => setStatus(data.status)}
                  _style="md:px-2 px-1 border-[1px] border-primary"
                  title={data.name}
                />
              );
            })}
          </nav>
          <div className="overflow-x-scroll ">
            <table className="  my-2  table-auto w-full ">
              <thead>
                <tr style={{ whiteSpace: "nowrap" }} className="p-3 bg-[#F8FDF9]">
                  {headerData.map((data, index) => {
                    return (
                      <th key={index} className=" p-3 border-r-2">
                        {data}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="my-3">{renderData}</tbody>
            </table>
          </div>
        </section>
      </Layout>}
    </>
  );
};

export default Bookings;
