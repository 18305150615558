import { Context, Items } from "../context/UserDetails";
import { WastesData } from "../data";
import { values } from "../types";
import { ImageComponent } from "./Tags/image";
import { useContext } from "react";
export const WasteComponent = ({
  // _style = "grid lg:grid-cols-4 bg-red-500 place-items-center  items-center justify-center mx-auto md:grid-cols-3 grid-cols-2",
  _style = "md:flex grid grid-cols-2 flex-wrap items-center gap-10 md:justify-center",
  Data = WastesData,
  items = [],
  click = true,
}: {
  _style?: string;
  Data?: Array<values>;
  items: Array<Items>;
  click?: boolean;
}): JSX.Element => {
  const contextValue = useContext(Context);
  const { user, setUser, selectedItems, setSelectedItems } = contextValue;
  return (
    <section className={`${_style}  gap-6  `}>
      {items?.map((i, index) => {
        const { name, photo, id } = i;
        return (
          <div
            className={`bg-secondary    flex flex-col justify-between   cursor ${
              selectedItems.map((e) => e.id).includes(id) && "fade"
            }`}
            key={index}
            onClick={() => {
              if (!click) return false;
              if (_style.includes("review")) return false;
              if (selectedItems.map((e) => e.id).includes(id)) {
                setSelectedItems(selectedItems.filter((d) => d.id != id));
              } else {
                setSelectedItems([...selectedItems, i]);
              }
            }}
          >
            <div className="p-5">
              <ImageComponent _style="w-44" src={photo as string} />
            </div>
            <p className="bg-primarySm capitalize text-center text-light p-3">
              {name}
            </p>
          </div>
        );
      })}
    </section>
  );
};
