import { ProductComponent } from "./ProductComponent";

import SubLayout from "../common/subLayout";

const Products = (): JSX.Element => {
  return (
    <SubLayout
      _style2="my-20 "
      subText="At the heart of our operations lies a dedication to recycling excellence. We leverage cutting-edge technologies to sort, process, and recycle materials, reducing the strain on natural resources"
      text="Our Products"
    >
      <ProductComponent />
    </SubLayout>
  );
};

export default Products;
