import React, { useState, useContext } from "react";
import Layout from "./common/layout";
import { modalProps } from "../types";
import { WasteComponent } from "../components/wasteComponent";
import { ButtonComponent } from "../components/Tags/button";
import { useModal } from "../context/modal";
import { WastesData } from "../data";
import { Context } from "../context/UserDetails";


const SelectWaste: React.FC<modalProps> = ({
  open,
  text,
  name,
}): JSX.Element => {
  const { modalToggle, initialState, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user, setUser,selectedItems, items } = contextValue;

  return (
    <>
      <Layout name={name} text={text} open={open}>
        <WasteComponent
          Data={WastesData.slice(0, 6)}
          items={items}
          _style="grid lg:grid-cols-4 gap-3 md:grid-cols-3 grid-cols-2"
        />
        <div className="flex justify-center my-5" onClick={() =>{
              if(selectedItems.length > 0){
                setModalToggle({ ...initialState, bookingOpen: true })
              }
        }}>
          <button
            className={`bg-primary text-white font-light text-base text-light font-light text-base text-light font-gsans-light px-5 py-2 rounded-md w-48 font-gsans-light`}
            >
            Create Booking
          </button>
        </div>
      </Layout>
    </>
  );
};

export default SelectWaste;
