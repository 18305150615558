import Layout from "../common/layout";
import dispose from "../assets/dispose.png";
import { ParagraphComponent } from "../components/Tags/paragraph";

import SubLayout from "../common/subLayout";
import { ImageComponent } from "../components/Tags/image";
import { Heading1Component } from "../components/Tags/heading";
import { ServicesType } from "../data";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../context/UserDetails";

const Services = (): JSX.Element => {
  const [loggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  const SectionComponent = () => {
    return (
      <section className=" text-center md:max-w-2xl mx-auto text-white  ">
        <h1 className="md:text-6xl text-3 uppercase font-hurl tracking-wider">
          Services
        </h1>
        <ParagraphComponent
          _style="text-white font-gsans-light  my-2 font-light text-light"
          text="Understanding your waste generation patterns is crucial for effective waste management. We conduct thorough waste audits to identify opportunities for waste reduction, recycling optimization, and cost savings. "
        />
      </section>
    );
  };
  return (
    <>
      {!loggedIn && (
        <Layout name="Services" SectionComponent={SectionComponent}>
          <SubLayout
            _style2="max-w-4xl mx-auto my-20"
            _style="max-w-5xl mx-auto"
            subText="Our smart solutions include waste tracking, data analytics, and reporting tools that empower you to make informed decisions, optimize waste streams, and enhance overall sustainability performance."
            text="The Services we offer"
          >
            {ServicesType.map((data, index) => {
              return (
                <div
                  key={data.title}
                  className="md:flex items-center my-10 justify-center     gap-10 md:justify-between"
                >
                  <div className={`max-w-xl  ${index % 2 !== 0 && "item"}  `}>
                    <Heading1Component text={data.title} />
                    <ParagraphComponent text={data.content} />
                  </div>
                  <ImageComponent
                    _style="md:w-72 w-80 md:mx-0 mx-auto self-start"
                    src={data.img}
                  />
                </div>
              );
            })}
          </SubLayout>
        </Layout>
      )}
    </>
  );
};

export default Services;
