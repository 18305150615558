import { routeType, buttonType, methodProps, values } from "./types";

// partners logo
import partner1 from "./assets/partner1.png";
import partner2 from "./assets/mss.png";

import partner6 from "./assets/lawma.png";
import partner7 from "./assets/oluseed.png";
import partner8 from "./assets/bbn.jpeg";

// waste types images
import waste1 from "./assets/waste1.png";
import waste2 from "./assets/waste2.png";

import waste6 from "./assets/waste6.png";
import waste7 from "./assets/waste7.png";
import waste9 from "./assets/waste9.png";
import waste10 from "./assets/waste10.png";

// services images
import service1 from "./assets/service1.jpg";
import service2 from "./assets/service2.jpg";
import service3 from "./assets/service3.jpg";
import service4 from "./assets/service4.jpg";

// collection methods images
import collection1 from "./assets/collection1.png";
import collection2 from "./assets/collection2.png";
import collection3 from "./assets/collection3.png";

import testimonial from "./assets/small_img.png";

import envelope from "./assets/envelope.png";
import phone from "./assets/phone.png";

// social media icons
import twitter from "./assets/twitter.png";
import facebook from "./assets/facebook.png";
import instagram from "./assets/instagram.png";
import linkedin from "./assets/linkedin.png";

import dispose from "./assets/dispose.png";

import team from "./assets/team.png";

import envelope2 from "./assets/envelope2.png";
import phone2 from "./assets/phone2.png";

// social media icons
import twitter2 from "./assets/twitter2.png";
import facebook2 from "./assets/facebook2.png";
import instagram2 from "./assets/instagram2.png";
import linkedin2 from "./assets/linkedin2.png";

import SwiperOptions from "swiper";

export const LinkRoutes: routeType = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    path: "/about",
  },
  {
    name: "Services",
    path: "/services",
  },
  // {
  //   name: "Blog",
  //   path: "/blog",
  // },
  {
    name: "Contact Us",
    path: "/contact",
  },
];

export const ButtonType: buttonType = {
  light: "bg-white text-primary",
  dark: "bg-primary text-white",
};

export const PartnerLogos = [partner6, partner1, partner2, partner7, partner8];

export const WastesData = [
  {
    name: "Pet containers",
    img: waste2,
  },

  {
    name: "Plastic buckets",
    img: waste7,
  },
  {
    name: "glass containers",
    img: waste9,
  },
  {
    name: "used papers",
    img: waste10,
  },
  {
    name: "Aluminium can containers",
    img: waste1,
  },
  {
    name: "Pure water sachets",
    img: waste6,
  },
];

export const MethodTypes: methodProps = [
  {
    title: "recyclables pick up",
    content:
      "As the global community continues to focus on environmental sustainability, GJC Waste Recycling Company emerges as a beacon of innovation, offering a comprehensive and convenient solution through their Recyclables Pick Up service. ",
    img: collection1,
  },
  {
    title: "recyclables drop off",
    content:
      "GJC Recycling understands the challenges individuals and businesses face when trying to responsibly dispose of recyclable materials. That's why the Recyclables Drop-off service has been designed to empower local communities, making recycling easier and more accessible than ever before. ",
    img: collection2,
  },
  {
    title: "corporate services",
    content:
      "As industries worldwide strive to reduce their carbon footprint and embrace eco-friendly practices, GJC's commitment to sustainable solutions extends beyond waste management. Our comprehensive corporate services cater to businesses seeking to align their operations with eco-conscious principles while boosting overall efficiency ",
    img: collection3,
  },
];

export const FaqContent = [
  {
    question: "What is GJC Recyling?",
    answer:
      "At GJC Recycling company, we stand out from the crowd. Our unique approach to waste management sets us apart and allows us to make a real difference in the lives of our customers and the environment. Here's what makes us special. ",
  },
  {
    question: " Can I recycle plastic bags?",
    answer:
      "Many recycling company do not accept plastic bags in curbside bins due to their potential to cause problems at recycling facilities. However, GJC Recycling have collection bins specifically for plastic bag recycling. ",
  },
];

export const testimonialDatas = [
  {
    name: "Felix Oluwaseun",
    country: "Nigeria",
    content:
      "I've tried a few waste management services in the past, and this one stands out. The online portal makes it simple to manage my account, and their regular communication keeps me informed about their environmental initiatives. It's a win-win!s",
    img: partner8,
  },
  {
    name: "Olajide Ayomide",
    country: "Nigeria",
    content:
      "I've been using this waste management service for over a year now, and I couldn't be happier! Their commitment to sustainability and eco-friendly practices is truly commendable. Efficient, reliable, and environmentally conscious – the perfect combination ",
    img: partner7,
  },
  {
    name: "Ajani Ben Dara",
    country: "Nigeria",
    content:
      "The team at [Waste Management Company] has exceeded my expectations. Their attention to detail and commitment to customer satisfaction set them apart. I'm impressed with how smoothly they integrate sustainability into their services. Highly recommended!",
    img: partner6,
  },
  {
    name: "Thomson Williams",
    country: "America",
    content:
      "I was skeptical about waste management services until I tried this one. The team is professional, and they make the entire process seamless. I love how they prioritize recycling and reducing environmental impact. Highly recommend! ",
    img: testimonial,
  },
  {
    name: "Oloyede Victor",
    country: "Nigeria",
    content:
      "I appreciate the transparency of this waste management company. They provide detailed reports on our waste generation and disposal, helping us track and reduce our environmental footprint. It's refreshing to work with a company that values accountability.",
    img: partner2,
  },
  {
    name: "Obi Chike",
    country: "Nigeria",
    content:
      "Choosing this waste management service was one of the best decisions I've made for my business. They not only manage our waste efficiently but also offer valuable insights on how we can reduce waste production. It's a partnership focused on sustainability.",
    img: partner1,
  },
];

export const BenefitData = [
  {
    title: "Best-in-class performance tracking",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection1,
  },
  {
    title: "Customer-centric model of operations",
    content:
      "Lorem ipsum dolor sit amet consectetur. Tincidunt ipsum enim tellus rhoncus mattis. Id maecenas tincidunt dapibus auctor lectus tortor ligula sed. Aliquam tortor viverra phasellus at euismod id faucibus sem. ",
    img: collection2,
  },
];

export const FooterRoutes = {
  qlinks: {
    title: "Quick Links",
    routes: [
      {
        path: "/",
        name: "Home",
      },
      {
        path: "/about",
        name: "About Us",
      },
      {
        path: "/contact",
        name: "Contact Us",
      },
    ],
  },
  services: {
    title: "Services",
    routes: [
      {
        path: "/",
        name: "Support",
      },
      {
        path: "/",
        name: "Cookie Policy",
      },
      {
        path: "/",
        name: "Privacy Policy",
      },
    ],
  },
  support: {
    title: "Support & Legal",
    routes: [
      {
        path: "/",
        name: "Recyclables PickUp",
      },
      {
        path: "/",
        name: "Recyclables Drop-off",
      },
      {
        path: "/",
        name: "Corporate Services",
      },
    ],
  },
  contact: {
    title: "Get in Touch",
    routes: [
      { icon: phone, name: "+2347065911838" },
      { icon: phone, name: "+2349126390288" },
      { icon: envelope, name: "info@gjcrecycling.com" },
    ],
    title2: "Social Media",
    routes2: [
      {
        icon: linkedin,
        name: "Linkedin",
        link: "https://www.linkedin.com/company/gjcrecycling",
      },
      {
        icon: instagram,
        name: "instagram",
        link: "https://www.instagram.com/gjcrecycling",
      },
      {
        icon: facebook,
        name: "facebook",
        link: "http://www.facebook.com/gjcrecycling",
      },
      {
        icon: twitter,
        name: "twitter",
        link: "https://x.com/gjcrecycling",
      },
    ],
  },
};

export const PorfolioData = [
  {
    title:
      "Unlocking Nigeria's Renewable Energy Potential through Effective Waste Management",
    content: `Introduction:
      As the world faces the pressing challenge of transitioning to sustainable energy sources, Nigeria stands at a critical crossroads. The nation's vast population, rapid urbanization, and industrial growth generate enormous amounts of waste. However, with the right approach to waste management, Nigeria has the potential to harness renewable energy and transform its energy landscape for a brighter, greener future. In this blog, we explore the relevance of waste management in achieving renewable energy goals in Nigeria and delve into the opportunities it presents for a sustainable and prosperous nation.
      
      The Growing Waste Dilemma in Nigeria:
      Nigeria, like many other developing countries, grapples with waste management challenges. According to a World Bank report, Nigeria generates over 32 million tons of solid waste annually, with a significant portion ending up in landfills or littering the environment. This waste crisis not only contributes to pollution but also represents a missed opportunity to harness valuable resources for renewable energy production.
      Waste-to-Energy: A Viable Solution:
      Effective waste management offers a promising pathway to unlock Nigeria's renewable energy potential. Waste-to-energy (WtE) technologies allow us to convert various waste types into renewable energy sources such as biogas, biodiesel, and electricity. This approach not only addresses the waste crisis but also diversifies the energy mix, reducing the country's reliance on fossil fuels.
      Biogas from Organic Waste:
      One of the most significant opportunities lies in harnessing biogas from organic waste. Biodegradable waste from agricultural residues, food scraps, and wastewater can be used to produce biogas through anaerobic digestion. Biogas can be utilized for electricity generation, cooking, and heating, benefiting both rural and urban communities. Implementing biogas projects across Nigeria could not only provide clean energy but also stimulate economic growth and job creation.
      The Power of Recycling:
      Recycling is an integral part of waste management and has a direct impact on energy conservation. By recycling materials such as paper, glass, and metals, we reduce the need for energy-intensive extraction and production processes. Additionally, recycling facilities can be designed to capture energy from the recycling process itself, further contributing to renewable energy generation.
      Engaging Communities for Lasting Impact:
      Successful waste management and renewable energy initiatives require the engagement and participation of communities. Education and awareness campaigns can empower citizens to adopt sustainable practices and contribute to waste separation, recycling, and renewable energy projects. Community involvement fosters a sense of ownership and responsibility, ensuring the long-term success of these initiatives.
      Conclusion:
      The potential for Nigeria to leverage waste management for renewable energy production is vast and promising. By adopting innovative waste-to-energy technologies, encouraging recycling, and engaging communities, Nigeria can not only tackle its waste challenges but also propel itself towards a sustainable energy future. The time to act is now, as each step we take towards effective waste management brings us closer to unlocking the full potential of renewable energy in Nigeria. Together, we can pave the way for a cleaner, greener, and more prosperous nation.

      `,
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
  {
    title: "King Baudouin African Development Prize 2023 -2024",
    content:
      "Lorem ipsum dolor sit amet consectetur. Sit amet tortor mauris nisl viverra semper mauris augue venenatis. Id aliquam ornare cursus sem amet nibh. Nec vestibulum diam semper facilisis platea ridiculus. Gravida scelerisque pellentesque egestas nisl nunc volutpat mi viverra. Gravida eget id id nunc sem hendrerit. Et sagittis maecenas non scelerisque lacus sed sit. Phasellus nisl eget senectus ipsum donec sed molestie facilisis.Tincidunt purus nibh quam risus blandit auctor. Turpis imperdiet lectus sit commodo diam non nam scelerisque faucibus. Tellus id odio integer nulla semper egestas eu eget. Risus aliquam dictum a integer vel at. Est neque scelerisque feugiat faucibus nulla. Mi pretium sed nulla cras arcu praesent. Velit pulvinar eget ullamcorper ac netus. Eget felis gravida id non pellentesque diam. Sit et ultricies at tristique neque tempor imperdiet arcu commodo. Cras amet iaculis ut nunc ipsum.Ullamcorper ullamcorper id eu consequat massa quisque amet velit amet. Pulvinar feugiat orci tortor magnis adipiscing scelerisque tempor. Hendrerit lectus lacinia massa dui. Lacinia tristique nunc massa diam nisl vestibulum elit. Dolor vulputate in ultricies orci nulla. At tincidunt in in nulla accumsan placerat cursus. At dui in facilisis ac porta est ullamcorper. Pharetra ipsum posuere aliquam ac sapien donec sagittis auctor nec. Sed senectus viverra  ",
    to: "/portfolio",
    img: dispose,
    date: "22-02-2020",
  },
];

export const TeamData = [
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
  {
    name: "Ajane Ben Dara",
    role: "C.E.O",
    img: team,
  },
];

export const pickUpData = [
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
  {
    place: "Ebute Meta Hub",
    areas: [
      "Ebute Meta",
      "Oyinbo",
      "Ajegunle",
      "Yaba",
      "Orile Alagomeji",
      "Costain",
    ],
    phone: "08107492331",
  },
];

export const dropData = [
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },

  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
  {
    place: "Gbagada Phase 2",
    address:
      "Canterbury Health Consult, 20B Lanre Awolokun St, Gbagada, Lagos.",
    number: "08107492331",
    link: "/",
  },
];

export const contactData = {
  contact: {
    title: "Get in Touch",
    routes: [
      { icon: phone2, name: "+23465911838" },
      { icon: envelope2, name: "info@gjcrecycling.com" },
    ],
    title2: "Social Media",
    routes2: [
      {
        icon: linkedin2,
        name: "Linkedin",
        link: "https://www.linkedin.com/company/gjcrecycling",
      },
      {
        icon: instagram2,
        name: "instagram",
        link: "https://www.instagram.com/gjcrecycling",
      },
      {
        icon: facebook2,
        name: "facebook",
        link: "http://www.facebook.com/gjcrecycling",
      },
      {
        icon: twitter2,
        name: "twitter",
        link: "https://x.com/gjcrecycling",
      },
    ],
  },
};

export const RegisterValues: values = {
  fullName: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const SidebarLinks = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    name: "Bookings",
    path: "/dashboard/bookings",
  },
  {
    name: "Profile",
    path: "/dashboard/profile",
  },
  {
    name: "Logout",
    path: "/login",
  },
];

export const OverviewData = [
  {
    name: "Total bookings",
    amount: 400,
    percent: "+11.01%",
    type: "credit",
  },
  {
    name: "Successful Pickup",
    amount: 300,
    percent: "+9.15%",
    type: "credit",
  },
  {
    name: "Pending Pickup ",
    amount: 2,
    percent: "-0.56%",
    type: "debit",
  },
  {
    name: "Cancelled Pickup",
    amount: 98,
    percent: "-1.48%",
    type: "debit",
  },
];

export const LanguageData = [
  {
    name: "English",
    value: "english",
  },
  {
    name: "French",
    value: "french",
  },
];
type pickUpType = Array<{
  bookingId: number;
  bookingDate: string;
  location: string;
  phone: string;
  pickupDate: string;
  status: string;
}>;
export const AllPickups: pickUpType = [
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "pending",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "successful ",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
  {
    bookingId: 245678,
    bookingDate: "11-01-2022",
    location: "Jacob Jones",
    phone: "08046829190",
    pickupDate: "11-01-2022",
    status: "cancelled",
  },
];

export const headerData = [
  "Booking ID",
  "Booking Date",
  "Location",
  "Phone Number",
  "Pickup date",
  "Booking Status",
];

export const buttonStates = [
  {
    name: "All Bookings",
    status: "all",
  },
  {
    name: "New Booking",
    status: "all",
  },
  {
    name: "Pending PickUp",
    status: "pending",
  },
  {
    name: "Successful Pickup",
    status: "successful",
  },
  {
    name: "Cancelled Pickup",
    status: "cancelled",
  },
];

export const swiperOptions: SwiperOptions = {
  centerInsufficientSlides: true,
  slidesPerView: 2,
  spaceBetween: 10,
  breakpoints: {
    500: {
      slidesPerView: 2,
    },
    840: {
      slidesPerView: 3,
    },
  },
  autoplay: {
    delay: 4000, // Set the delay between slide transitions in milliseconds
    // Allow autoplay to continue even when the user interacts with the swiper
  },

  pagination: {
    clickable: true,
  },
};

export const swiperOptions2: SwiperOptions = {
  centerInsufficientSlides: true,
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    500: {
      slidesPerView: 1,
    },
    840: {
      slidesPerView: 3,
    },
  },
  autoplay: {
    delay: 4000, // Set the delay between slide transitions in milliseconds
    // Allow autoplay to continue even when the user interacts with the swiper
  },

  pagination: {
    clickable: true,
  },
};

export const servicesSwiperOption: SwiperOptions = {
  centerInsufficientSlides: true,
  slidesPerView: 1,
  spaceBetween: 10,

  autoplay: {
    delay: 4000, // Set the delay between slide transitions in milliseconds
    // Allow autoplay to continue even when the user interacts with the swiper
  },

  pagination: {
    clickable: true,
  },
};

export const aboutData = [
  {
    title: "Converting Waste to Cash",
    text: " We believe that waste should not be seen as a burden but as a valuable resource. By partnering with us, you have the opportunity to turn your waste into cash. We buy various recyclable materials, including plastics, from households, estates, and communities in Ibadan. So, instead of throwing your waste away, you can now make a profit from it.",
  },
  {
    title: "Eco-Friendly Practices  ",
    text: " We are committed to sustainability and minimizing our ecological footprint. Our waste management processes prioritize recycling and proper disposal, ensuring that materials are given a new life or disposed of responsibly. By working with us, you can contribute to a cleaner and healthier environment for future generations.",
  },
  {
    title: "Convenience and Efficiency",
    text: "We understand that waste management should be hassle-free and convenient. That's why we have designed a seamless process that makes it easy for you to sell your recyclable waste to us. Whether you're an individual, a household, or a community, we provide efficient collection and payment systems, ensuring a smooth experience for everyone involved.",
  },
  {
    title: "Community Engagement",
    text: " We believe that waste management is a collective effort. We actively engage with the community, raising awareness about the importance of waste reduction and recycling. Through educational initiatives and partnerships, we empower individuals and communities to take an active role in preserving the environment and building a sustainable future together.",
  },
];
export const missionData = [
  {
    title:
      "Creating awareness among individual, organization, and community on recyclable materials.",
    text: "  At GJC Recycling, we are committed to spreading awareness about the importance of recycling among individuals, organizations, and communities. Together, we can turn waste into valuable resources and build a sustainable future. Join us in making a positive impact on the environment and turning recyclable materials into a force for good.",
  },
  {
    title:
      "Engage technology innovation for disseminating information and aggregate recyclable.  ",
    text: " GJC Recycling Revolutionizes Recycling Awareness. GJC Recycling leverages cutting-edge technology to disseminate vital information and aggregate recyclable materials. With a strong commitment to environmental sustainability, GJC empowers individuals, organizations, and communities to contribute to a greener future. Together, we can transform waste into valuable resources, promoting a cleaner and more sustainable planet.",
  },
  {
    title:
      "Deploying state-of-the-art equipment to transform waste to a valuable asset.",
    text: "GJC Recycling leads the charge in sustainable waste management, utilizing cutting-edge technology to convert waste into valuable resources. By harnessing state-of-the-art equipment, the company empowers communities to make a positive impact on the environment while unlocking new opportunities for economic growth. Join GJC Recycling today and be a part of the green revolution.",
  },
  {
    title: "Community Engagement",
    text: " Embracing the vision of waste as a valuable resource, GJC Recycling offers specialized training to unlock the potential of recyclable materials. Through this innovative approach, individuals and communities can pave the way to financial gains while contributing to a cleaner environment. Join us in this empowering journey toward a sustainable future.",
  },
];

export const ServicesType: methodProps = [
  {
    title: "Buying of waste",
    content:
      "As the global community continues to focus on environmental sustainability, GJC Waste Recycling Company emerges as a beacon of innovation, offering a comprehensive and convenient solution through their Recyclables Pick Up service. ",
    img: service1,
  },
  {
    title: "Exportable Pet Flake",
    content:
      "We employ cutting-edge recycling technologies to transform post-consumer PET bottles into high-quality flakes. Our advanced processing ensures the removal of impurities and contaminants, resulting in a clean and reliable product. ",
    img: service2,
  },
  {
    title: "Post Consumer Pet",
    content:
      "We employ state-of-the-art recycling technologies to ensure that every PET bottle collected is transformed into premium-quality Post-Consumer PET. Our advanced processes guarantee the removal of impurities, resulting in a material that meets or exceeds industry standards.",
    img: service3,
  },
  {
    title: "Post Industrial Pet",
    content:
      "We employ cutting-edge recycling techniques to ensure the highest quality of Post-Industrial PET. Our processes are designed to eliminate impurities, resulting in a material that not only meets industry standards but surpasses them.",
    img: service3,
  },
  {
    title: "Post Industrial Pet",
    content:
      "As industries worldwide strive to reduce their carbon footprint and embrace eco-friendly practices, GJC's commitment to sustainable solutions extends beyond waste management. Our comprehensive corporate services cater to businesses seeking to align their operations with eco-conscious principles while boosting overall efficiency ",
    img: service4,
  },
];
