import { InputComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import React, { useState } from "react";
import { values } from "../types";
import { validateComponent } from "../utils/validate";
const PasswordComponent: React.FC<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  values: values;
  setValues: React.Dispatch<React.SetStateAction<values>>;
  validateInputs: Function;
  loading: Boolean;
}> = ({ setOpen, values, setValues, validateInputs, loading }): JSX.Element => {
  const createPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validateInputs();
  };

  return (
    <form className="">
      <InputComponent
        _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
        values={values}
        setValues={setValues}
        name="password"
        label="Create Password"
        type="password"
        placeholder="********"
      />
      <InputComponent
        _style="  border-[1px] rounded-md  shadow-none p-3 border-[#C2C2C2]"
        values={values}
        setValues={setValues}
        name="confirmPassword"
        label="Confirm Password"
        type="password"
        placeholder="********"
      />

      <div className="my-5 gap-5 flex flex-col">
        <ButtonComponent
          clickHandler={(e) => !loading && createPassword(e)}
          _style={`py-4 my-2 ${loading && "not-allowed"}`}
          type={loading ? "light" : "dark"}
          title={loading ? "Loading..." : "Create Password"}
        />
      </div>
    </form>
  );
};

export default PasswordComponent;
