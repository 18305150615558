import { WasteComponent } from "./wasteComponent";

import SubLayout from "../common/subLayout";
import { Items } from "../context/UserDetails";
const Wastes: React.FC<{ items: Array<Items> }> = ({ items }): JSX.Element => {
  return (
    <SubLayout
      _style2="my-20 "
      subText="At GJC Recycling, we're unique. Our innovative waste management creates real impact for customers and the environment. Here's why we stand out."
      text="what we accept"
    >
      <WasteComponent items={items} />
    </SubLayout>
  );
};

export default Wastes;
