import { OverviewData } from "../../data";
import overviewBg from "../../assets/overviewBg.png";
import credit from "../../assets/vector1.png";
import debit from "../../assets/vector2.png";
import { useState, useEffect } from "react";
import { Items, UserInfo } from "../../context/UserDetails";
import { ResponseBody, getDashboardStats, getGrowthStats } from "../../api/handlers";
import { useNavigate } from "react-router";
import { useModal } from "../../context/modal";
export const OverviewComponent : React.FC<{user : UserInfo | null, items: Array<Items> }> = ({user}): JSX.Element => {
  const navigate = useNavigate();
  const {setModalToggle, initialState, modalToggle} = useModal()
  const [data, setData] = useState([
    {
      name: "Total bookings",
      amount: 0,
      percent: "+0%",
      type: "credit",
    },
    {
      name: "Successful Pickup",
      amount: 0,
      percent: "+0%",
      type: "credit",
    },
    {
      name: "Pending Pickup ",
      amount: 0,
      percent: "+0%",
      type: "debit",
    },
    {
      name: "Cancelled Pickup",
      amount: 0,
      percent: "+0%",
      type: "debit",
    },
  ]);
  useEffect(()=>{
    async function getStats(){
      try {
        const bookingStats : ResponseBody | undefined= await getBookingStats() as ResponseBody;
        const bookingGrowth : ResponseBody | undefined = await getGrowth() as ResponseBody;
        setData([
          {
            name: "Total bookings",
            amount: bookingStats.data?.totalBookings ?? 0,
            percent: `${bookingGrowth.data?.totalBookingsGrowth >= 0 ? "+" : "-"}${bookingGrowth.data?.totalBookingsGrowth}%` ?? "0%",
            type: `${bookingGrowth.data?.totalBookingsGrowth >= 0 ? 'credit' : 'debit'}`,
          },
          {
            name: "Successful Pickup",
            amount: bookingStats.data?.successfulBookings ?? 0,
            percent: `${bookingGrowth.data?.successfulBookingsGrowth >= 0 ? "+" : "-"}${bookingGrowth.data?.successfulBookingsGrowth}%` ?? "0%",
            type: `${bookingGrowth.data?.successfulBookingsGrowth >= 0 ? 'credit' : 'debit'}`,
          },
          {
            name: "Pending Pickup ",
            amount: bookingStats.data?.pendingBookings ?? 0,
            percent: `${bookingGrowth.data?.pendingBookingsGrowth >= 0 ? "+" : "-"}${bookingGrowth.data?.pendingBookingsGrowth}%` ?? "0%",
            type: `${bookingGrowth.data?.pendingBookingsGrowth >= 0 ? 'credit' : 'debit'}`,
          },
          {
            name: "Cancelled Pickup",
            amount: bookingStats.data?.cancelledBookings ?? 0,
            percent: `${bookingGrowth.data?.cancelledBookingsGrowth >= 0 ? "+" : "-"}${bookingGrowth.data?.cancelledBookingsGrowth}%` ?? "0%",
            type: `${bookingGrowth.data?.cancelledBookingsGrowth >= 0 ? 'credit' : 'debit'}`,
          },
        ])
      } catch (error) {
        
      }
    }
    getStats()
    
  }, [modalToggle]);

  async function getBookingStats(){
    try {
      const response : ResponseBody = await getDashboardStats(user?.accessToken as string);

      if(response.status == 201){
        return response
      }else{
        localStorage.removeItem('user');  
        navigate('/');
      }
    } catch (error) {
      localStorage.removeItem('user');     
      navigate('/');
    }

  } 
  async function getGrowth(){
    try {
      const response : ResponseBody = await getGrowthStats(user?.accessToken as string);

      if(response.status == 201){
        return response
      }else{
        localStorage.removeItem('user');  
        navigate('/');
      }
    } catch (error) {
      localStorage.removeItem('user');     
      navigate('/');
    }

  } 
  const renderOverview = data.map((data, index) => {
    return (
      <div
        style={{ backgroundImage: `url(${overviewBg})` }}
        className="bg-primary text-white lg:p-5 md:p-1 p-2 rounded-md"
      >
        <h2 className="text-base tracking-wider ">{data.name}</h2>
        <div className="flex  items-center justify-between">
          <h1 className="lg:text-4xl md:text-xl text-xl font-bold">
            {data.amount}
          </h1>
          <div className="my-4 flex items-center gap-3">
            <p>{data.percent}</p>
            <img src={data.type === "credit" ? credit : debit} />
          </div>
        </div>
      </div>
    );
  });
  return (
    <section className="grid md:grid-cols-4 grid-cols-2 gap-4  md:gap-3 lg:gap-5">
      {renderOverview}
    </section>
  );
};
