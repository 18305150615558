import React, { useState } from "react";
import { Heading1Component } from "../components/Tags/heading";
import { ParagraphComponent } from "../components/Tags/paragraph";
import { NavLink } from "react-router-dom";
import { ImageComponent } from "../components/Tags/image";
import share from "../assets/share.png";
import { ButtonType } from "../data";

export type blogItemProps = {
  data: any;
  index: any;
};
const BlogItem: React.FC<blogItemProps> = ({ data, index }) => {
  const [mode, setMode] = useState("short");
  return (
    <div
      key={index}
      className="md:flex  items-center my-10     gap-2 justify-between"
    >
      <div className={`max-w-xl  ${index % 2 === 0 && "item"}  `}>
        <Heading1Component _style="text-primary text-xl" text={data.title} />
        <ParagraphComponent
          text={
            mode == "full"
              ? data.content
              : data?.content?.substring(0, 1000) + "..."
          }
        />
        <p className="text-right font-bold text-sm">Posted {data.date}</p>
        <button
          className={`${ButtonType["dark"]} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md`}
          onClick={() => setMode("full")}
        >
          Read More
        </button>
        {/* <NavLink
            className=" my-5 flex items-center w-28 justify-between bg-primary rounded-md py-2 px-4 text-light font-gsans-light"
            to={data.to}
            >
            <span>
                <img className="w-6" src={share} alt="share icon" />
                
            </span>
            <span>Share</span>
            </NavLink> */}
      </div>
      <ImageComponent src={data.img} _style="w-96 self-start" />
    </div>
  );
};

export default BlogItem;
