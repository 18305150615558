import Layout from "../common/layout";
import { PorfolioData } from "../data";
import { Heading1Component } from "../components/Tags/heading";
import { ParagraphComponent } from "../components/Tags/paragraph";
import Band from "../components/band";
import FaqsComponent from "../components/faqs";
import { NavLink, useNavigate } from "react-router-dom";
import { ImageComponent } from "../components/Tags/image";
import { useState, useEffect } from "react";
import SubLayout from "../common/subLayout";
import share from "../assets/share.png";
import { getUser } from "../context/UserDetails";
import BlogItem from "./Blogitem";

const Portfolio = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  const SectionComponent = () => {
    return (
      <section className="uppercase text-center text-white  ">
        {/* <h1 className="md:text-9xl text-4xl font-hurl tracking-wider">
          Award <span className="font-huri">&</span> Recognitions
        </h1> */}
      </section>
    );
  };
  return (
    <>
      {!loggedIn && (
        <Layout name="Portfolio" SectionComponent={SectionComponent}>
          <SubLayout _style="max-w-5xl">
            {PorfolioData.map((data, index) => {
              return <BlogItem data={data} index={index} />;
            })}
          </SubLayout>
          <Band />
          <FaqsComponent />
        </Layout>
      )}
    </>
  );
};

export default Portfolio;
