import { PartnerLogos } from "../data";

import SubLayout from "../common/subLayout";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { swiperOptions } from "../data";
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

const Partners = (): JSX.Element => {
  SwiperCore.use([Autoplay]);

  return (
    <SubLayout
      _style="max-w-5xl mx-auto"
      text="Our top partners"
      subText="Our mission and vision is to build a sustainable future in which waste recycling is entrenched in every individual, organization, and community. "
    >
      <div className="flex md:overflow-x-hidden overflow-x-scroll items-center my-10 justify-between">
        <Swiper className="justify-between flex" {...swiperOptions}>
          {PartnerLogos.map((data, index) => {
            return (
              <SwiperSlide>
                <div className="md:h-36 p-2 md:w-36 h-24 w-24 border-slate-500 border  rounded-full overflow-hidden">
                  <img
                    key={index}
                    className="mx-auto w-full h-full object-contain"
                    src={data}
                    alt="partner log"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </SubLayout>
  );
};

export default Partners;
