import React from "react";

import { Heading1Component } from "../components/Tags/heading";
import { ParagraphComponent } from "../components/Tags/paragraph";

import { motion } from "framer-motion";
import { pageVariant, transition } from "../variants";
import { NavLink } from "react-router-dom";

import { MdKeyboardBackspace } from "react-icons/md";

type layoutProp = {
  children: JSX.Element;
  title?: string;
  text?: string;
  subTitle?: string;
};

const Layout: React.FC<layoutProp> = ({
  children,
  text,
  title,
  subTitle,
}): JSX.Element => {
  return (
    <>
      <motion.main
        variants={pageVariant}
        initial="initial"
        animate="animate"
        transition={transition}
        className="md:flex text-white bg-light md:bg-[#FFFCF2]  md:h-screen overflow-y-hidden  "
      >
        <section
          // style={{ backgroundImage: `url(${formBg})` }}
          className="bg-primary flex flex-col form-layout relative md:rounded-e-md flex-1  p-4   md:p-10"
        >
          <div className="max-w-sm">
            <Heading1Component _style="my-5" text="GJC  Recycling" />
            <ParagraphComponent
              _style="text-white text-light my-3 font-gsans-light"
              text="Unlocking Nigeria's Renewable Energy Potential Through Effective Waste Management."
            />
          </div>
          <NavLink className="my-5 flex items-center gap-4" to="/">
            <MdKeyboardBackspace />{" "}
            <span className="font-mono"> Go To Home</span>
          </NavLink>
        </section>
        <section className="md:flex-1 w-full">
          <section className="  flex w-full md:items-center md:justify-center px-10 flex-col ">
            <div className="text-dark  md:p-8 p-4 rounded-2xl  md:bg-light md:w-auto w-full">
              <h2 className="text-dark text-2xl font-bold">{title}</h2>
              <p className="text-base capitalize text-lightXs ">{text}</p>{" "}
              <div className="my-6">
                <h2 className="text-dark text-xl font-bold">{subTitle}</h2>
                {children}
              </div>
            </div>
          </section>
        </section>
      </motion.main>
    </>
  );
};

export default Layout;
