import Layout from "../common/layout";
import Partners from "../components/partners";
import Convert from "../components/convert";
import Wastes from "../components/wastes";
// import Band from "../components/band";
import WasteMethods from "../components/methods";
import MobileApp from "../components/mobileApp";
import FaqsComponent from "../components/faqs";
import Testimonials from "../components/testimonials";
import WhyUs from "../components/whyus";
import { ButtonComponent } from "../components/Tags/button";
import { useState, useEffect, useContext } from "react";
import { ParagraphComponent } from "../components/Tags/paragraph";

import { ImageComponent } from "../components/Tags/image";

import heroBg from "../assets/heroBg.jpg";
import service1 from "../assets/hero1.jpg";
import service2 from "../assets/hero6.jpg";
import service3 from "../assets/hero3.jpg";
import service4 from "../assets/hero4.jpg";
import service5 from "../assets/hero5.jpg";
import { useNavigate } from "react-router";
import { Context, getUser } from "../context/UserDetails";
import { servicesSwiperOption } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import Products from "../components/Product";

const SectionComponent = () => {
  const navigate = useNavigate();
  return (
    <section className=" grid md:grid-cols-2 grid-cols-1 gap-6 items-center p-3    max-w-5xl justify-between mx-auto">
      <div className="max-w-xl">
        <h1 className="text-4xl font-gsans-bold  capitalize tracking-wider text-shadow font-bold text-light">
          Convert your waste into money with GJC Recycling
        </h1>

        <ParagraphComponent
          text="Unlocking Nigeria's Renewable Energy Potential Through Effective Waste Management."
          type="light"
        />
        <ButtonComponent
          clickHandler={() => navigate("/login")}
          _style="w-32"
          title="Sell Waste"
          type="light"
        />
      </div>
      <div>
        <Swiper className="mySwiper" {...servicesSwiperOption}>
          <SwiperSlide>
            {" "}
            <ImageComponent _style="md:w-[27rem] w-96 h-72" src={service4} />
          </SwiperSlide>
          <SwiperSlide>
            <ImageComponent _style="md:w-[27rem] w-96 h-72" src={service1} />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <ImageComponent _style="md:w-[27rem] w-96 h-72" src={service2} />
          </SwiperSlide>

          <SwiperSlide>
            {" "}
            <ImageComponent _style="md:w-[27rem] w-96 h-72" src={service3} />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <ImageComponent _style="md:w-[27rem] w-96 h-72" src={service5} />
          </SwiperSlide>
        </Swiper>

        {/* <img src={heroBg} alt="hero background" /> */}
      </div>
    </section>
  );
};

const HomePage = (): JSX.Element => {
  const [loggedIn] = useState<Boolean>(getUser().loggedIn || false);
  const contextValue = useContext(Context);
  const { items } = contextValue;
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);

  console.log({ items });
  return (
    <>
      {!loggedIn && (
        <Layout name="Home" SectionComponent={SectionComponent}>
          <Convert />
          <Wastes items={items} />
          <Products />
          {/* <Band /> */}
          <WasteMethods />
          <Partners />
          <MobileApp />
          <FaqsComponent />
          <Testimonials />
          <WhyUs />
        </Layout>
      )}
    </>
  );
};

export default HomePage;
