import { modalProps } from "../types";
import Layout from '../booking/common/layout'
import { InputComponent, SelectComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import { useState, useEffect } from "react";
import { useModal } from "../context/modal";
import { WasteComponent } from "../components/wasteComponent";
import { DeleteComponent, DoneComponent } from "../booking/subModal";
import { Context, Items } from "../context/UserDetails";
import {useContext}from 'react'
import { ButtonType, WastesData } from "../data";
import { useNavigate } from "react-router";
import { ResponseBody, cancelBooking } from "../api/handlers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type valueProps = {
  [key: string]: string;
};

const BookingDetails: React.FC<modalProps> = ({ open, name, text }): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen]= useState(false)
  const contextValue = useContext(Context);
  const { user,setUser,selectedItems, items, selectedBooking, locations } = contextValue;
  const [wastes, setWastes] = useState<Array<Items>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const bookingValues = {
    firstName: "Ajani Ben Dara",
    email: "gjc@gmail.com",
    phone: "08100000001",
    phone2: "08100000001",
    address: "Ikeja",
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModalToggle(initialState);
  };

  const handleDelete = () =>{
    setModalToggle(initialState);
    setDeleteOpen(true)
  }
  const [values, setValues] = useState<valueProps>(bookingValues);

  const handleClick= async () => {
    try {
      setLoading(true);
      const response: ResponseBody = await cancelBooking(user?.accessToken as string, {bookingId : selectedBooking?.id});
      setLoading(false);
      if(response?.status == 201){
        toast.success("Success!");
        setTimeout(()=>setModalToggle({...initialState, openDetails : false}), 500);
      }else{
        toast.error(response?.message)
        setTimeout(()=>setModalToggle({...initialState, openDetails : false}), 500);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(()=>{
    const allBookingItems : Array<string> = selectedBooking?.items || [] as Array<string>;
    const wastesData= allBookingItems?.map((i) =>{
      return {
        id : i,
        name : items?.filter(x => x?.id == i)?.[0]?.name,
        photo : items?.filter(x => x?.id == i)?.[0]?.photo,
      }
    });
    setWastes(wastesData);
    
  },[selectedBooking]);
  return (
    <>

    <DeleteComponent open={deleteOpen} setOpen={setDeleteOpen}/>

      <Layout width="min-w-[60vw]" name={name} open={open} text={text}>
        <h2 className="text-primary tracking-wider text-base">
        Booking Details
        </h2>
        <div className="flex justify-between items-center">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Booking ID
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={selectedBooking?.bookingId}
                />
              </div>
              </div>
              <h2 className="text-primary tracking-wider my-4 text-xs font-medium   ">
              Selected pick Up waste Items
        </h2>
        <WasteComponent
          items={wastes}
          _style="grid lg:grid-cols-4 my-2 gap-3 md:grid-cols-3 grid-cols-2"
          click={false}
        />
        <form>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Basic Information
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Full Name
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={user?.fullName}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Email
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={user?.email}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Phone Number
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={user?.phone}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Additional Phone Number
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={selectedBooking?.additionalPhone}
                />
              </div>
            </div>
            <h3 className="text-primary my-3 font-semibold text-sm">
              This number will be used for verification
            </h3>
          </section>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Pick Up details
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Pick Up Address
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={selectedBooking?.pickUpAddress}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Area
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={locations?.filter(i =>i?.id == selectedBooking?.pickUpLocation)[0]?.name || ""}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Pick Up Date
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={selectedBooking?.pickUpDate?.substring(0,10)}
                />
              </div>
              <div className="flex my-3 flex-col">
                <label className="pb-3 text-sm" htmlFor='fullName'>
                Pick Up Time Range
                </label>
                <input
                  className={`text-sm outline-none p-2 shadow-md`}
                  type='text'
                  disabled
                  value={selectedBooking?.pickUpTime}
                />
              </div>
            </div>
          </section>
          <div className="flex my-3 items-center justify-center">
          <button onClick={(e)=>{
            e.preventDefault();
            handleClick();
          }} className={`bg-red-400 text-white rounded-md px-4 py- ${loading && ButtonType['light']} ${loading && 'not-allowed'} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md`}>{loading ? "Loading..." : "Cancel Booking"}</button>
          </div>
        </form>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default BookingDetails;