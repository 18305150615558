import { modalProps } from "../types";
import Layout from "./common/layout";

import { InputComponent, SelectComponent } from "../components/Tags/input";
import { ButtonComponent } from "../components/Tags/button";
import { useState, useContext } from "react";
import { useModal } from "../context/modal";

import { WasteComponent } from "../components/wasteComponent";

import { DoneComponent } from "./subModal";

import { ButtonType, WastesData } from "../data";
import { useNavigate } from "react-router";
import { Context } from "../context/UserDetails";
import { createNewBooking } from "../api/handlers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type valueProps = {
  [key: string]: string;
};

const Review: React.FC<modalProps> = ({ open, name, text }): JSX.Element => {
  const { initialState, setModalToggle } = useModal();
  const contextValue = useContext(Context);
  const { user, setUser, items, selectedItems, setSelectedItems, values, setValues,locations } = contextValue;
  const navigate = useNavigate();
  const [action, setAction] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setModalToggle({ ...initialState, otpOpen: true });
    setAction(true);
    navigate("/dashboard/bookings");
  };

  const createBooking = async () =>{
    try {
      setLoading(true);
      const response= await createNewBooking(user?.accessToken as string, {
        items : selectedItems.map(i => i.id),
        additionalPhone : values?.phone,
        pickUpAddress : values?.address,
        pickUpLocation : values?.location,
        pickUpDate : values?.pickUpDate,
        pickUpTimeOne : `${values.pickUpDate}T${values?.pickUpTimeOne}`,
        pickUpTimeTwo : `${values.pickUpDate}T${values?.pickUpTimeTwo}`,
      } );
      setLoading(false);
      console.log(response);
      
      if(response.status == 201){
        values.bookingId = response.data.bookingId;
        setSelectedItems([]);
        setModalToggle({ ...initialState, otpOpen: true });
        setAction(true);
        navigate("/dashboard/bookings");
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  return (
    <>
      <DoneComponent open={action} setOpen={setAction} />
      <Layout width="min-w-[60vw]" name={name} open={open} text={text}>
        <h2 className="text-primary tracking-wider text-base">
          Selected pick Up waste items
        </h2>
        <WasteComponent
          Data={WastesData.slice(3, 5)}
          items={selectedItems}
          _style="grid lg:grid-cols-4 my-2 gap-3 md:grid-cols-3 grid-cols-2 review"
        />
        <form>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Basic Information
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <InputComponent
                name="firstName"
                values={values}
                setValues={setValues}
                label="Full Name"
                disabled
              />
              <InputComponent
                name="email"
                type="email"
                values={values}
                setValues={setValues}
                label="Email Adress"
                disabled
              />
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <div className="flex my-3 flex-col">
              <label className="pb-3 text-sm" htmlFor='phone'>
                Phone Number
              </label>
              <input
                className='text-sm outline-none p-2 shadow-md'
                type='text'
                value={user?.phone}
                disabled
              />
            </div>

              <InputComponent
                name="phone"
                type="text"
                values={values}
                setValues={setValues}
                label="Additional Phone Number"
                disabled
              />
            </div>
            <h3 className="text-primary my-3 font-semibold text-sm">
              This number will be used for verification
            </h3>
          </section>
          <section>
            <h2 className="text-xl capitalize text-primary font-gsans-light">
              Pick Up details
            </h2>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <InputComponent
                name="address"
                values={values}
                setValues={setValues}
                label="Pick Up Address"
                disabled
              />
              
            <div className="flex my-3 flex-col">
              <label className="pb-3 text-sm" htmlFor='Area'>
                Area
              </label>
              <input
                className={`text-sm outline-none p-2 shadow-md`}
                type='text'
                value={locations?.filter(i => i.id == values?.location)[0]?.name}
                name='location'
                disabled
              />
            </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 gap-1">
              <InputComponent
                name="pickUpDate"
                values={values}
                type="date"
                setValues={setValues}
                label="Pick Up Date"
                disabled
              />
              <div className="flex my-3 flex-col">
              <label className="pb-3 text-sm" htmlFor='Area'>
                Pick up time range
              </label>
              <input
                className={`text-sm outline-none p-2 shadow-md`}
                type='text'
                value={`${values?.pickUpTimeOne} - ${values?.pickUpTimeTwo}`}
                name='pick up time'
                disabled
              />
            </div>
            </div>
          </section>
          <div className="flex my-3 items-center justify-center">
            <button
              className={`${loading ? ButtonType['light'] : ButtonType['dark']} font-light text-base text-light font-gsans-light px-5 py-2 rounded-md ${loading && "not-allowed"}`}
              onClick={(e)=> {
                e.preventDefault()
                if(!loading)createBooking();
              }}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      <ToastContainer />
      </Layout>
    </>
  );
};

export default Review;
