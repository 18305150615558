import Layout from "../common/layout";
import dispose from "../assets/dispose.png";
import { ParagraphComponent } from "../components/Tags/paragraph";
import Partners from "../components/partners";
import Band from "../components/band";
import SubLayout from "../common/subLayout";
import Team from "../components/team";

import { aboutData, missionData } from "../data";
import { Heading1Component } from "../components/Tags/heading";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getUser } from "../context/UserDetails";

const About = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  const SectionComponent = () => {
    return (
      <section className=" text-center md:max-w-2xl mx-auto text-white  ">
        <h1 className="md:text-6xl text-3 uppercase font-hurl tracking-wider">
          Our Story
        </h1>
        <ParagraphComponent
          _style="text-white font-gsans-light  my-2 font-light text-light"
          text="At GJC Recycling company, we stand out from the crowd. Our unique approach to waste management sets us apart and allows us to make a real difference in the lives of our customers and the environment. Here's what makes us special "
        />
      </section>
    );
  };
  return (
    <>
      {!loggedIn && (
        <Layout name="About Us" SectionComponent={SectionComponent}>
          <SubLayout _style="max-w-5xl my-6  mx-auto">
            <h1 className="text-center text-xl my-5 font-black font-gsans-bold text-primary md:text-3xl">
              Our Mission Statement
            </h1>
            {missionData.map((data, index) => {
              return (
                <div className="">
                  <h1 className="text-xl font-bold">{data.title}</h1>
                  <ParagraphComponent _style="" text={data.text} />
                </div>
              );
            })}
          </SubLayout>
          <SubLayout _style="max-w-5xl my-6  mx-auto">
            <h1 className="text-center text-xl my-5 font-black font-gsans-bold text-primary md:text-3xl">
              What Makes Us Special
            </h1>
            {aboutData.map((data, index) => {
              return (
                <div className="">
                  <h1 className="text-xl font-bold">{data.title}</h1>
                  <ParagraphComponent _style="" text={data.text} />
                </div>
              );
            })}
          </SubLayout>

          {/* <Team /> */}
          <Partners />
          <Band />
        </Layout>
      )}
    </>
  );
};

export default About;
