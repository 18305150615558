import Layout from "../common/layout";
import { ButtonComponent } from "../components/Tags/button";
import { ParagraphComponent } from "../components/Tags/paragraph";
import FaqsComponent from "../components/faqs";
import Band from "../components/band";
import Wastes from "../components/wastes";
import Pickup from "../components/pickup";
import Drop from "../components/drop";
import ContactForm from "../components/contact";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Context, getUser } from "../context/UserDetails";

const Contact = (): JSX.Element => {
  const [loggedIn, setLoggedIn] = useState<Boolean>(
    getUser().loggedIn || false
  );
  const contextValue = useContext(Context);
  const { user, setUser, items } = contextValue;
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn) {
      navigate("/dashboard");
    }
  }, []);
  const SectionComponent = () => {
    return (
      <section className=" text-center max-w-4xl mx-auto text-white  ">
        <h1 className="md:text-6xl text-3xl uppercase font-hurl tracking-wider">
          CONTACT US
        </h1>
        <ParagraphComponent
          _style="text-white font-gsans-light my-3 font-light text-light"
          text="At GJC Recycling company, we stand out from the crowd. Our unique approach to waste management sets us apart and allows us to make a real difference in the lives of our customers and the environment. Here's what makes us special. "
        />
        <ButtonComponent type="light" title="Contact Us" />
      </section>
    );
  };
  return (
    <>
      {!loggedIn && (
        <Layout name="Contact" SectionComponent={SectionComponent}>
          <section>
            <div className="hidden">
              <FaqsComponent />
            </div>
            <ContactForm />
            <Pickup />
            <div className="hidden">
              <Wastes items={items} />
            </div>
            <Drop />
            <Band />
          </section>
        </Layout>
      )}
    </>
  );
};

export default Contact;
