import logo from "../assets/logo2.jpg";
import logo2 from "../assets/logo3.png";
export const LogoComponent = (): JSX.Element => {
  return (
    <div className="logo">
      <img className="md:w-28 w-28" src={logo} alt="GJC Logo" />
    </div>
  );
};

export const LightLogoComponent = (): JSX.Element => {
  return (
    <div className="logo">
      <img className="md:w-28 w-28" src={logo2} alt="GJC Logo" />
    </div>
  );
};
